import * as React from "react";
import { Header, Container, Segment, Icon } from "semantic-ui-react";
import { withLayout } from "../components/Layout";
import Helmet from "react-helmet";

const AboutPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="info circle" />
          <Header.Content>About</Header.Content>
        </Header>
      </Segment>
      <Segment vertical>
        <p>
          Royston Shufflebotham is a Front-end developer and architect at IBM,
          in Cambridge, UK.
        </p>

        <p>
          He got his first taste of coding in 1981, and really hasn't stopped
          since then, working his way through backend and frontend jobs in text
          matching, web &amp; PKI infrastructure and crypto, finance, web server
          software, laboratory automation and investigative analysis, journeying
          through Quick Basic, Perl, TCL, C++ (several times), Delphi, C#, Java
          (several times) and JavaScript.
        </p>
        <p>
          He appears to have finally settled down as a Front-end developer,
          preferring to work in React and TypeScript.
        </p>
        <p>
          He also has a <em>very</em> unhealthy fondness for fiddling round
          with, or writing, developer tools instead of writing real production
          code.
        </p>

        <p>
          <a
            href="https://twitter.com/RoystonShuff?ref_src=twsrc%5Etfw"
            className="twitter-follow-button"
            data-size="large"
            data-show-count="false"
          >
            Follow @RoystonShuff
          </a>
          <Helmet>
            <script
              type="text/javascript"
              src="https://platform.twitter.com/widgets.js"
            />
          </Helmet>
        </p>
        <hr />

        <p>
          <strong>NOTE</strong>: This is Royston's <strong>personal</strong>{" "}
          blog.
        </p>
        <p>
          Whilst the author does take some care to ensure the information
          presented here is accurate and useful, he makes no claims or
          representations as to accuracy, completeness, correctness, suitability
          or validity of any information on this site, and refuses to be liable
          for any errors, omissions, or delays in this information or any
          losses, injuries or damages arising from its display or use. All
          information is provided on an as-is basis with no warranties, and
          confers no rights. There is no warranty that the site is free of
          viruses or other harmful components. It is the reader's responsibility
          to verify their own facts and to secure their own systems.
        </p>
        <p>
          The views, thoughts and opinions expressed in this blog are those of
          the author only and do not necessarily reflect the official policy or
          position of any other agency, organization, employer or company,
          including, but not limited to, IBM.
        </p>
        <p>
          These views are also subject to change, revision and rethinking at any
          time; please do not hold us to them in perpetuity.
        </p>
        <p>
          Comments on this website, however, are the sole responsibility of
          their authors, and those authors will take full responsibility,
          liability and blame for any libel or litigation that result from
          something written in or as a direct result of something written in a
          comment. Therefore, feel free to challenge and disagree in the
          comments section - but we reserve the right to delete any comment for
          any reason whatsoever (abusive, profane, rude, etc) - so keep it
          polite and relevant. Please?
        </p>

        <hr />

        <p>
          Oh, and in case you're wondering, it's pronounced{" "}
          <code>ˈrɔɪstən ˈʃʌfl̩bɑθəm</code>
          <span> (or </span>
          <code>ˈrɔɪstən ˈʃʌfl̩bəʊθəm</code>
          <span> if it's easier for you)</span>
          <a href="https://accenthero.com/pronounce-english-words-correctly">
            (guide)
          </a>
          . Yeah, that's definitely almost certainly helpful...
        </p>
      </Segment>
    </Container>
  );
};

export default withLayout(AboutPage);
